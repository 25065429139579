@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

// Color system

$white: #ffffff;
$alice-blue: #f1f5f9;
$white-smoke: #f6f6f6;
$silver: #c3c3c3;
$hawkes-blue: #c3dcff;
$alto: #d8d8d8;
$mercury: #e5e5e5;
$emperor: #555454;
$midnight-express: #1a212e;
$black-russian: #0f151f;
$black: #000000;
$camarone: #007717;
$blue-ribbon: #1739f1;
$punch: #dc3545;

$colors: (
  "white": $white,
  "alice-blue": $alice-blue,
  "white-smoke": $white-smoke,
  "silver": $silver,
  "hawkes-blue": $hawkes-blue,
  "alto": $alto,
  "mercury": $mercury,
  "emperor": $emperor,
  "midnight-express": $midnight-express,
  "black-russian": $black-russian,
  "camarone": $camarone,
  "blue-ribbon": $blue-ribbon,
  "punch": $punch,
);

$body: $alice-blue;
$dark: $midnight-express;
$dark-highlighted: $black-russian;
$paper: $white-smoke;
$light: $silver;
$light-accent: $hawkes-blue;
$primary: $blue-ribbon;
$secondary: $emperor;
$secondary-contrast: $mercury;
$success: $camarone;
$danger: $punch;

$theme-colors: (
  "white": $white,
  "body": $body,
  "dark": $dark,
  "dark-highlighted": $dark-highlighted,
  "primary": $primary,
  "secondary": $secondary,
  "secondary-contrast": $secondary-contrast,
  "paper": $paper,
  "light": $light,
  "light-accent": $light-accent,
  "success": $success,
  "danger": $danger,
  "black": $black,
);

// Z-index

$zindex-overlay: $zindex-fixed + 1;
$zindex-overlay-over: $zindex-overlay + 1;

// Spacing

$spacers: (
  1: $spacer * 0.125,
  2: $spacer * 0.25,
  3: $spacer * 0.5,
  4: $spacer * 1,
  5: $spacer * 1.5,
  6: $spacer * 3,
);

// Body

$body-bg: $body;
$body-color: $black;

// Typography

$enable-responsive-font-sizes: true;

$font-family-roboto: "Roboto";
$font-family-base: $font-family-roboto;

$font-weight-medium: 500;
$font-weight-semibold: 600;

$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.125;

$display4-size: $font-size-base * 2.625;

$display4-weight: 500;

$headings-margin-bottom: 0;

$paragraph-margin-bottom: 0;

// List group

$list-group-bg: $dark;
$list-group-border-width: 0;

$list-group-hover-bg: $dark-highlighted;
$list-group-active-color: $white;
$list-group-active-bg: $dark-highlighted;

$list-group-action-color: $light;
$list-group-action-hover-color: $white;

$list-group-action-active-color: $white;
$list-group-action-active-bg: $dark-highlighted;

$list-group-action-active-marker-color: $body-bg;
$list-group-action-active-marker-height: 1rem;
$list-group-action-active-marker-width: 0.75rem;

// Cards

$card-spacer-y: 1rem;
$card-spacer-x: 1.5rem;
$card-cap-bg: $dark;
$card-cap-color: $white;
$card-border-width: 0;
$card-border-radius: $border-radius;

// Shadows

$box-shadow-sm: 0 3px 6px 0 rgba($black, 0.16);

// Forms

$form-group-margin-bottom: 0;

// Sidebar

$sidebar-width: 16rem;
$sidebar-transition: margin 0.25s ease-in-out;

// Scrollbar

$scrollbar-width: 0.375rem;
