@import "./settings";

// Next.js layout.

#__next {
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
}

// Scrollbar

.scrollbar {
  &-primary {
    &::-webkit-scrollbar {
      width: $scrollbar-width;

      &-track {
        background-color: $alto;
      }

      &-thumb {
        background-color: $primary;
      }
    }
  }
}

// Overwrite elements defaults.

textarea {
  resize: none;
}

a {
  color: $primary;

  &:hover,
  &:focus {
    color: $primary;
  }
}

// Additional classes.

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparentize($black, 0.5);
  z-index: $zindex-overlay;
}

// Expansion of the basic Bootstrap classes.

.z-index {
  &-0 {
    z-index: 0;
  }

  &-1 {
    z-index: 1;
  }
}

.font {
  &-weight {
    &-medium {
      font-weight: $font-weight-medium;
    }
    &-semibold {
      font-weight: $font-weight-semibold;
    }
  }
}

.overflow {
  &-x {
    &-auto {
      overflow-x: auto;
    }
  }

  &-y {
    &-auto {
      overflow-x: auto;
    }
  }
}

.text {
  &-transform {
    &-initial {
      text-transform: initial;
    }
  }
}

// Bootstrap styling overwriting.

.card {
  overflow: hidden;

  &-body {
    padding: $card-spacer-y $card-spacer-x;
  }
}

.btn {
  box-shadow: $box-shadow-sm;
  font-family: $font-family-roboto;

  &[class*="btn-outline"] {
    box-shadow: none;
  }

  &-inline {
    display: inline;
    border: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    color: $primary;

    &:hover,
    &:focus {
      text-decoration: underline;
      color: $primary;
    }
  }

  &-not-styled {
    border: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.form-check-input {
  &.is-invalid {
    & ~ .form-check-label {
      a {
        color: $danger;
      }
    }
  }
}
